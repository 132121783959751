import business from "../images/homepage/programs/1.png";
import invest from "../images/homepage/programs/2.png";
import startup from "../images/homepage/programs/3.png";
import family from "../images/homepage/programs/4.png";
import study from "../images/homepage/programs/5.png";
import life from "../images/homepage/programs/6.png";

export const programsData = [
  {
    name: "Express Entry",
    slug: "/programs",
    programs: [
      // {
      //   name: "Canadian Experience Class (CEC)",
      //   subPrograms: [],
      //   slug: "/programs/canadian-experience-class",
      // },
      // {
      //   name: "Federal Skilled Worker (FSW)",
      //   subPrograms: [],
      //   slug: "/programs/federal-skilled-worker",
      // },
      // {
      //   name: "Federal Skilled Trades",
      //   subPrograms: [],
      //   slug: "/programs/federal-skilled-trades",
      // },
    ],
  },
  {
    name: "Provincial Nominee Programs",
    slug: "/programs/provincial-nominee-program",
    programs: [
      {
        name: "Alberta",
        subPrograms: [
          {
            name: "Express Entry",
            slug: "/programs/alberta-express-entry",
          },
          {
            name: "Accelerated Tech Pathway",
            slug: "/programs/alberta-accelerated-tech-pathway",
          },
          {
            name: "Foreign Graduate Entrepreneur Stream",
            slug: "/programs/alberta-foreign-graduate-entrepreneur-stream",
          },
          {
            name: "Farm Stream",
            slug: "/programs/alberta-farm-stream",
          },
          {
            name: "Rural Renewal Stream",
            slug: "/programs/alberta-rural-renewal-stream",
          },
        ],
      },
      {
        name: "British Columbia",
        subPrograms: [
          {
            name: "Express Entry",
            slug: "/programs/british-columbia-express-entry",
          },
          {
            name: "Skills Immigration Stream",
            slug: "/programs/british-columbia-skills-immigration-stream",
          },
          {
            name: "PNP Tech",
            slug: "/programs/british-columbia-pnp-tech",
          },
          {
            name: "Entrepreneur Immigration Stream",
            slug: "/programs/british-columbia-entrepreneur-immigration-stream",
          },
        ],
      },
      {
        name: "Manitoba",
        subPrograms: [
          {
            name: "Skilled Workers Overseas",
            slug: "/programs/manitoba-skilled-workers-overseas",
          },
          {
            name: "Entrepreneur Pathway",
            slug: "/programs/manitoba-entrepreneur-pathway",
          },
          {
            name: "Farm Investor Pathway",
            slug: "/programs/manitoba-farm-investor-pathway",
          },
        ],
      },
      {
        name: "New Brunswick",
        subPrograms: [
          {
            name: "Express Entry Stream",
            slug: "/programs/new-brunswick-express-entry",
          },
          {
            name: "Skilled Worker Applicants with Employer Support",
            slug: "/programs/new-brunswick-skilled-worker-applicants-with-employer-support",
          },
        ],
      },
      {
        name: "Newfoundland and Labrador",
        subPrograms: [
          {
            name: "Skilled Worker Stream",
            slug: "/programs/newfoundland-and-labrador-skilled-worker-stream",
          },
          {
            name: "International Entrepreneur Stream",
            slug: "/programs/newfoundland-and-labrador-international-entrepreneur-stream",
          },
          {
            name: "Priority Skills",
            slug: "/programs/newfoundland-and-labrador-priority-skills",
          },
        ],
      },
      {
        name: "Nova Scotia",
        subPrograms: [
          {
            name: "Entrepreneur Stream",
            slug: "/programs/nova-scotia-entrepreneur-stream",
          },
          {
            name: "Skilled Worker Stream",
            slug: "/programs/nova-scotia-skilled-worker-stream",
          },
          {
            name: "Physician Stream",
            slug: "/programs/nova-scotia-physician-stream",
          },
          {
            name: "Labour Market Priorities",
            slug: "/programs/nova-scotia-labour-market-priorities",
          },
          {
            name: "Occupations in Demand",
            slug: "/programs/nova-scotia-occupations-in-demand",
          },
        ],
      },
      {
        name: "Ontario",
        subPrograms: [
          {
            name: "Human Capital Priorities",
            slug: "/programs/ontario-human-capital-priorities",
          },
          {
            name: "French-Speaking Skilled Worker",
            slug: "/programs/ontario-french-speaking-skilled-worker",
          },
          {
            name: "Skilled Trades",
            slug: "/programs/ontario-skilled-trades",
          },
          {
            name: "Regional Immigration Pilot",
            slug: "/programs/ontario-regional-immigration-pilot",
          },
        ],
      },
      {
        name: "Prince Edward Island",
        subPrograms: [
          {
            name: "Provincial Nomination Program",
            slug: "/programs/prince-edward-island-provincial-nomination-program",
          },
          {
            name: "Work Permit Stream",
            slug: "/programs/prince-edward-island-work-permit-stream",
          },
          {
            name: "Skilled Worker Outside Canada Stream",
            slug: "/programs/prince-edward-island-skilled-worker-outside-canada-stream",
          },
        ],
      },
      {
        name: "Saskatchewan",
        slug: "/programs/express-entry",
        subPrograms: [
          {
            name: "Express Entry",
            slug: "/programs/saskatchewan-express-entry",
          },
          {
            name: "Employment Offer",
            slug: "/programs/saskatchewan-employment-offer",
          },
          {
            name: "Occupation In-Demand",
            slug: "/programs/saskatchewan-occupation-in-demand",
          },
          {
            name: "Entrepreneur and Farm",
            slug: "/programs/saskatchewan-entrepreneur-and-farm",
          },
        ],
      },
    ],
  },
  {
    name: "Atlantic Immigration Program",
    slug: "/programs/atlantic-immigration-program",
    programs: [],
  },
  {
    name: "Rural and Northern Immigration Pilot",
    slug: "/programs/rural-and-northern-immigration-pilot",
    programs: [],
  },
  {
    name: "Startup Visa Program",
    slug: "/programs/startup-visa-program",
    programs: [],
  },
  {
    name: "Agri-Food Pilot",
    slug: "/programs/agri-food-pilot",
    programs: [],
  },
];
export const programData = [
  {
    heading:
      "Navigate Canada's Business Landscape: Multiple Business Immigration Program",
    content:
      "Considering Canada for your company's operations and work permits leading to permanent residency? The Intra-Company Transfer (ICT) program is your solution. Facilitating foreign companies to extend into Canada, relocating executives, managers, and specialized employees seamlessly. Eligibility criteria for both the company and applicant apply. Explore the ICT program for smooth growth in Canada's business environment.",
  },
  {
    heading:
      "Embrace Canadian Business Prospects: Uncover Federal and Provincial Entrepreneurial Initiatives",
    content:
      "Dive into Canada's diverse Business Immigration Programs, including the Ontario Provincial Nominee Program (PNP). Crafted for adept business owners and senior managers seeking an Ontario lifestyle. Secure your future by launching a new venture or acquiring an existing one while actively managing day-to-day operations. Embark on your Canadian business journey today.",
  },
  {
    heading:
      "Elevate your entrepreneurial journey and access a world of possibilities in Canada through the Start-Up Visa.",
    content:
      "For visionary entrepreneurs aspiring to establish a firm foothold in Canada, the Start-Up Visa (SUV) presents a compelling avenue. This distinguished program facilitates permanent residence through business immigration, catering to both ingenious start-up pioneers and accomplished leaders of foreign enterprises. Should you meet the program's stipulated prerequisites, you can even extend this opportunity to include up to five of your fellow founders.",
  },
  {
    heading: "Uniting Families in Canada: Your Path to Sponsorship",
    content:
      "Canada, a nation devoted to family unity, presents immigration programs allowing Canadians to sponsor loved ones to join them. Explore avenues to reunite families under our compassionate sponsorship initiatives. Begin the journey to keep your loved ones close in Canada.",
  },
  {
    heading: "Empower Your Future: Study in Canada's Diverse Excellence.",
    content:
      "Elevate your prospects with a Canadian education. Join international students in choosing Canada for top-ranked schools, safety, and a welcoming environment based on diversity and tolerance. Unlock a brighter future through quality education in Canada.",
  },
  {
    heading: "Your Path to Canada: Navigating Immigration Opportunities",
    content:
      "Embarking on your Canada immigration journey is a personalized experience, influenced by your profile and connections. With elevated Canadian immigration goals, seize this moment to begin your voyage. While some paths might seem smoother, remember, the journey involves multiple steps. Choose your way wisely and start your Canadian dream.",
  },
];
export const programCategories = [
  {
    title: "Expand my BUSINESS",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/expand-business",
  },
  {
    title: "Invest in or buy a BUSINESS",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/business",
  },
  {
    title: "Launch or grow my STARTUPS",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/startups",
  },
  {
    title: "Join my FAMILY MEMBER",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/family-member",
  },
  {
    title: "STUDY",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/study",
  },
  {
    title: "Live a BETTER LIFE",
    image:
      "https://res.cloudinary.com/dzd6o8dyv/image/upload/f_auto,q_auto/v1/Homepage/programs/better-life",
  },
];
