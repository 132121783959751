import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "gatsby";
import Button from "../Button";
import { navigate } from "gatsby";

import Logo from "../../images/infokool-logo.webp";

type MenuProps = {
  onClose: () => void;
  isOpen: boolean;
};

type NavProps = {
  children: React.ReactNode;
  path: string;
  onClose: () => void;
};

const NavLink = (props: NavProps) => {
  const { children, path, onClose } = props;
  return (
    <Link
      to={`/${path.toLocaleLowerCase()}`}
      style={{ textAlign: "center" }}
      onClick={onClose}
    >
      <Box
        className="hover-underline-animation"
        px={2}
        fontSize={"1rem"}
        p={{ base: "1rem 2rem", sm: "1rem" }}
        fontWeight={{ base: 600, sm: 600, md: 500 }}
        rounded={"md"}
        color="#28329D"
        _hover={{
          textDecoration: "none",
          color: "#D65050",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const MenuDrawer = (props: MenuProps) => {
  const { onClose, isOpen } = props;
  const Links = ["Products", "About", "Solutions"];
  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={{ base: "full" }}
      placement="left"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton fontSize="md" mr={3} />
        <DrawerHeader>
          <Link to="/">
            <Image src={Logo} alt="logo" width={150} />
          </Link>
        </DrawerHeader>
        <DrawerBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            as="nav"
            spacing={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {Links.map((link) => (
              <NavLink key={link} path={link} onClose={onClose}>
                {link}
              </NavLink>
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
