import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "poppins",
      },
    },
  },
  colors: {
    primary: {
      400: "#0196EF", // light
      500: "#0292ED", // primary,
      600: "#120697",
      700: "#80FF8E", //accent
    },
    fontColors: {
      100: "black",
      200: "white",
    },
  },
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
});

export default theme;
