import {
  Box,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { programsData } from "../../data/programsData";
import "./products-popover.css";

interface Props {
  pathname: string;
  scrolling: boolean;
}
const ProductsPopover = (props: Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { pathname, scrolling } = props;

  const activeProgram = pathname?.split("/")[2];
  const activeProgramIndex = programsData[1].programs.findIndex(
    (program) =>
      program.name?.toLowerCase().replace(/\s+/g, "-") === activeProgram
  );

  const activeSubProgram = pathname?.split("/")[3];
  const activeSubprogramIndex = programsData[1].programs[
    activeProgramIndex
  ]?.subPrograms.findIndex(
    (program) =>
      program.name?.toLowerCase().replace(/\s+/g, "-") === activeSubProgram
  );

  const data = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        nodes {
          name
          slug
          id
          products {
            id
            title
            slug
            sub_category {
              name
            }
          }
        }
      }
    }
  `);

  const categoryData: any = {};

  for (const node of data.allStrapiCategory.nodes) {
    categoryData[node.name] = { slug: node.slug, products: {} };
    const subCategoryData: any = {};
    for (const product of node.products) {
      if (product.sub_category != null) {
        if (subCategoryData.hasOwnProperty(product.sub_category.name)) {
          subCategoryData[product.sub_category.name].push({
            title: product.title,
            slug: product.slug,
          });
        } else {
          subCategoryData[product.sub_category.name] = [
            { title: product.title, slug: product.slug },
          ];
        }
      } else {
        if (subCategoryData.hasOwnProperty(product.title)) {
        } else {
          if (!subCategoryData["default"]) {
            subCategoryData["default"] = [];
          }
          subCategoryData["default"].push({
            title: product.title,
            slug: product.slug,
          });
        }
      }
    }
    categoryData[node.name]["products"] = subCategoryData;
  }

  console.log(categoryData);

  const [active, setActive] = React.useState<number>(-1);
  const [subActive, setSubActive] = React.useState<number>(-1);

  return (
    <Popover
      onClose={() => setActive(-1)}
      trigger="hover"
      preventOverflow
      onOpen={() => {
        setActive(activeProgramIndex !== -1 ? activeProgramIndex : 0);
        setSubActive(activeSubprogramIndex !== -1 ? activeSubprogramIndex : 0);
      }}
    >
      <PopoverTrigger>
        <Box
          cursor={"pointer"}
          className={
            pathname === "/" && !scrolling
              ? "hover-underline-animation landing-page-nav"
              : "hover-underline-animation"
          }
          fontSize="1rem"
          fontWeight={"bold"}
          // m="0 1rem"
          rounded="md"
          color={"black"}
          _hover={{
            textDecoration: "none",
            color: "black",
          }}
        >
          Products
        </Box>
      </PopoverTrigger>
      <PopoverContent w={"100%"}>
        {/* <PopoverArrow /> */}
        {/* <PopoverHeader>All programs</PopoverHeader> */}
        <PopoverBody
          bgColor={"gray.100"}
          py={20}
          px={20}
          // width="calc(100vw - 50px)"
          display={"flex"}
          gap={20}
        >
          {Object.keys(categoryData).map((cat: any) => {
            const category = categoryData[cat];
            return (
              <>
                <Box
                  // color={"primary.500"}
                  fontSize={"md"}
                  display={"flex"}
                  flexDir={"column"}
                  gap={4}
                >
                  <Link to={`/${category.slug}`}>
                    <Text fontSize={20} fontWeight={"bold"}>
                      {cat}
                    </Text>
                  </Link>
                  {Object.keys(category.products).map((prod: any) => {
                    const product = category.products[prod];
                    if (prod == "default") {
                      return product.map((e: any) => (
                        <Link to={`/${e.slug}`}>
                          <Text fontSize={18}>{e.title}</Text>
                        </Link>
                      ));
                    }
                    return (
                      <>
                        <Text
                          fontSize={18}
                          fontWeight={"medium"}
                          textDecor={"underline"}
                        >
                          {prod}
                        </Text>
                        {product.map((e: any) => (
                          <Link to={`/${e.slug}`}>
                            <Text fontSize={16}>{e.title}</Text>
                          </Link>
                        ))}
                      </>
                    );
                  })}
                </Box>
                <Box border={"0.2px solid"} borderColor={"primary.500"}></Box>
              </>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProductsPopover;
