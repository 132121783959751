import * as React from "react";
import {
  Box,
  Drawer,
  // Flex,
  HStack,
  // useDisclosure,
  Image,
  Text,
  useDisclosure,
  // Container,
} from "@chakra-ui/react";
import { Link } from "gatsby";
// import { HamburgerIcon } from "@chakra-ui/icons";
import MenuDrawer from "../Drawer";
import { useLocation } from "@reach/router";
import "./Header.css";
import Logo from "../../images/infokool-logo.webp";
import ProductsPopover from "../ProductsPopover";
import { HamburgerIcon } from "@chakra-ui/icons";

interface Props {
  children: React.ReactNode;
  path: string;
  pathname: string;
  scrolling: boolean;
}

const Links = ["About", "Solutions"];

const NavLink = (props: Props) => {
  const { children, path, pathname, scrolling } = props;
  return (
    <Link to={`/${path.toLocaleLowerCase().split(" ")[0]}`}>
      <Box
        className={
          pathname === "/" && !scrolling
            ? "hover-underline-animation landing-page-nav"
            : pathname === `/${path.toLocaleLowerCase().split(" ")[0]}/`
            ? "underline-nav-item"
            : "hover-underline-animation"
        }
        textDecor={pathname === path ? "underline" : ""}
        textUnderlineOffset={3}
        fontSize="1rem"
        fontWeight={"bold"}
        // m="0 1rem"
        rounded="md"
        color={"black"}
        _hover={{
          textDecoration:
            pathname === `/${path.toLocaleLowerCase().split(" ")[0]}/`
              ? "underline"
              : "none",
          color: pathname === "/" && !scrolling ? "fonts.100" : "fonts.100",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();

  const handleClick = () => {
    onOpen();
  };

  const [scrolling, setScrolling] = React.useState(false);

  React.useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <>
      <Box
        w={"100vw"}
        px={{ lg: scrolling ? 0 : 16, sm: 0 }}
        pt={{ lg: scrolling ? 0 : 10, sm: 0 }}
        transition={"0.5s ease"}
        // h={28}
        display={"flex"}
        justifyContent={"center"}
        alignItems={!scrolling ? "flex-start" : "flex-end"}
        bgColor={"transparent"}
        pos={"fixed"}
        top={0}
        zIndex={100}
      >
        <Box
          display={"flex"}
          boxShadow={"0 4px 2px -2px #000000"}
          borderRadius={{ lg: scrolling ? 0 : 20, sm: 20 }}
          transition={"0.5s ease"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bgColor={"white"}
          color={"black"}
          w={"100%"}
          zIndex={111}
          // h={14}
          px={{ base: 5, md: 20, lg: 20 }}
          py={4}
        >
          <HamburgerIcon
            aria-label={"Open Menu"}
            ml="1rem"
            fontSize="2xl"
            display={{ md: "none" }}
            onClick={handleClick}
          />
          <MenuDrawer isOpen={isOpen} onClose={onClose} />

          <Link to="/">
            <Box
              display={"flex"}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Image src={Logo} alt="logo" width={32} />
            </Box>
          </Link>
          <HStack
            gap={10}
            fontWeight={500}
            display={{ base: "none", md: "flex" }}
          >
            <ProductsPopover scrolling={scrolling} pathname={pathname} />
            {Links.map((link) => (
              <NavLink
                key={link}
                path={link}
                pathname={pathname}
                scrolling={scrolling}
              >
                {link}
              </NavLink>
            ))}
          </HStack>
          <Link to="/contact">
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              w={32}
              transition={"0.3s ease"}
              p={2}
              cursor={"pointer"}
              _hover={{
                boxShadow:
                  "2px 2px 0px 0px var(--chakra-colors-fontColors-100)",
              }}
              borderRadius={30}
              bgColor={"primary.500"}
            >
              <Text color={"white"} fontSize={13} fontWeight={"semibold"}>
                Enquire now
              </Text>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};
export default Header;
