import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Input } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import { emailValidation } from "../../validations";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import FooterLogo from "../../images/infokool-logo.webp";
import {
  BsFacebook,
  BsLinkedin,
  BsEnvelope,
  BsEnvelopeAt,
  BsPhone,
  BsTelephone,
  BsYoutube,
} from "react-icons/bs";

const Footer = (props: any) => {
  let { pathname } = useLocation();

  const [email, setEmail] = React.useState("");
  //Handle Email Validation
  const [isEmailValid, setEmailValid] = React.useState(true);
  const handleEmail = (e: any) => {
    let email = e.target.value.trim();

    if (!email || emailValidation().test(email) === false) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEmail(email);
    }
  };
  const handleSubmit = () => {
    console.log("email", email);
  };

  return (
    <Box>
      <Flex
        borderTop={"1px solid black"}
        backgroundColor="white"
        color="#black"
        paddingY={{ base: 5, sm: 5, md: 10, lg: 10 }}
        paddingX={{ base: 10, sm: 10, md: 10, lg: 24 }}
        justify="center"
        flexDirection={{ base: "column", sm: "column", md: "row" }}
      >
        <Flex
          direction="column"
          color={"black"}
          gap={2.5}
          padding={2.5}
          flex={1}
        >
          <Box>
            <Box display={"flex"} alignItems={"center"}>
              <Link to="/">
                <Image src={FooterLogo} alt="logo" width={36} />
              </Link>
            </Box>
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              fontWeight={"bold"}
            >
              Office Address
            </Text>
          </Box>
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            B-12,6&7,PARASNATH COMPLEX,
          </Text>
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            OWALI VILLAGE,
          </Text>
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            BHIWANDI, THANE MAHARASHTRA,
          </Text>
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
          >
            INDIA - 421302
          </Text>
        </Flex>
        <Divider
          display={{ base: "block", sm: "block", md: "none" }}
          marginY={5}
        />
        {/* <Flex justify="space-between" flex={2}> */}
        <Flex direction="column" gap={2.5} padding={2.5} flex={1}>
          <Text
            fontSize={{ base: "md", sm: "md", lg: "2xl" }}
            color={"black"}
            fontWeight={600}
          >
            Important Links
          </Text>
          <Link to="/associate-program/">
            <Text
              fontWeight={pathname === "/associate-program/" ? 700 : 500}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              color={pathname === "/associate-program/" ? "black" : ""}
              className={
                pathname === "/associate-program/"
                  ? ""
                  : "hover-underline-animation"
              }
            >
              Become an associate
            </Text>
          </Link>
          {/* <Link to="/privacy-policy">
              <Text
                fontWeight={pathname === "/privacy-policy/" ? 700 : 500}
                color={pathname === "/privacy-policy/" ? "black" : ""}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/privacy-policy/"
                    ? ""
                    : "hover-underline-animation"
                }
              >
                Privacy Policy
              </Text>
            </Link>
            <Link to="/terms-of-service">
              <Text
                fontWeight={pathname === "/terms-of-service/" ? 700 : 500}
                color={pathname === "/terms-of-service/" ? "black" : ""}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/terms-of-service/"
                    ? ""
                    : "hover-underline-animation"
                }
              >
                Terms of Service
              </Text>
            </Link> */}
          <Link to="/careers">
            <Text
              fontWeight={pathname === "/careers/" ? 700 : 500}
              color={pathname === "/careers" ? "black" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/careers/" ? "" : "hover-underline-animation"
              }
            >
              Careers
            </Text>
          </Link>
          <Link to="/contact">
            <Text
              fontWeight={pathname === "/contact/" ? 700 : 500}
              color={pathname === "/contact" ? "black" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/contact/" ? "" : "hover-underline-animation"
              }
            >
              Contact
            </Text>
          </Link>
          {/* <Link to="/call">
            <Text
              fontWeight={pathname === "/call/" ? 700 : 500}
              color={pathname === "/call/" ? "primary.600" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/call/" ? "" : "hover-underline-animation"
              }>
              Book a call
            </Text>
          </Link> */}
        </Flex>
        <Divider
          display={{ base: "block", sm: "block", md: "none" }}
          marginY={5}
        />
        <Flex
          direction="column"
          gap={6}
          padding={2.5}
          justifyContent={"flex-start"}
          flex={1}
        >
          <Text fontSize={{ base: "md", sm: "md", lg: "2xl" }} fontWeight={600}>
            Social Connects
          </Text>
          <Box w={"100%"} display={"flex"} gap={5}>
            <Link
              to="https://www.facebook.com/profile.php?id=61563878514336"
              target="_blank"
            >
              <BsFacebook size={32} color="#1877F2" cursor={"pointer"} />
            </Link>
            <Link to="mailto:info@infokool.com">
              <BsEnvelopeAt size={32} color="#0077b5" cursor={"pointer"} />
            </Link>
            <Link to="tel:+919321907841">
              <BsTelephone size={32} color="#0077b5" cursor={"pointer"} />
            </Link>
            <Link to="http://www.youtube.com/@Infokool" target="_blank">
              <BsYoutube size={35} color="red" cursor={"pointer"} />
            </Link>
          </Box>
          {/* <Box
            w="100%"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              borderRadius={30}
              p={2.5}
              bgColor={"#ffe497"}
              fontWeight={500}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
            >
              Submit
            </Text>
          </Box> */}
        </Flex>
        {/* </Flex> */}

        {/* <Flex direction="column" gap={5} padding={2.5} flex={1}>
        <Text fontSize={{ base: "md", sm: "md", lg: "2xl" }} fontWeight={600}>
          Stay up to date
        </Text>
        <FormControl
          width={{ base: "100%", sm: "fit-content", md: "fit-content" }}
        >
          <InputGroup>
            <Input
              type="email"
              placeholder="Email Address"
              background="#EFEFEF"
              color="#1a202c"
              _placeholder={{ color: "#24258280" }}
              onChange={handleEmail}
            />
            <InputRightElement cursor="pointer" onClick={handleSubmit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="#F64C72"
                />
              </svg>
            </InputRightElement>
          </InputGroup>
          {!isEmailValid && (
            <FormHelperText fontSize="12px" color="#FF3333">
              Enter a valid email address
            </FormHelperText>
          )}
        </FormControl>
      </Flex> */}
      </Flex>
      <Box
        p={2}
        w={"100%"}
        bgColor={"primary.500"}
        display={"flex"}
        gap={5}
        justifyContent={"space-evenly"}
        flexDir={{ base: "column", lg: "row" }}
        alignItems={"center"}
      >
        <Text color={"white"} fontSize={12}>
          Copyright &copy; 2024 - Infokool Solution Pvt. Ltd | All Rights
          Reserved.
        </Text>
        <Text color={"white"} fontSize={12}>
          Designed and developed by Sparsh Singh
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
